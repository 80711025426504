body {
  background-color: #282c34;
  height: 100vh;
}

html,
body {
  overflow: auto;
}

.container {
  padding: 16px 0;
}
oading-spinner {
  animation-play-state: running;
  opacity: 1;
  position: relative;
  height: 50px;
}

@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
.loading-spinner {
  position: relative;
  height: 50px;
}
.loading-spinner.-paused {
  animation-play-state: paused;
  opacity: 0.2;
  transition: opacity linear 0.2s;
}

.loading-spinner::before {
  animation: 0.5s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 1.5px #dedede;
  border-bottom-color: #414159;
  border-radius: 50%;
  content: "";
  height: 30px;
  left: 50%;
  opacity: inherit;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 30px;
  will-change: transform;
}

body {
  background-color: #ffffff;
}
